@import "~animate.css/animate.min.css";
.row, #landing .content .lead .subscribe, #landing .content, .channels, .title, footer, header nav, header, button,
a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column, #features .info, #features .ui, #how .illustration, .more, #landing .content .hero, #landing .content .lead, .wrapper, .bg, section {
  display: flex;
  flex-direction: column;
}

.w30 {
  width: 30%;
}

.h30 {
  height: 30%;
}

.w40 {
  width: 40%;
}

.h40 {
  height: 40%;
}

.w50 {
  width: 50%;
}

.h50 {
  height: 50%;
}

.w60 {
  width: 60%;
}

.h60 {
  height: 60%;
}

.w70 {
  width: 70%;
}

.h70 {
  height: 70%;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.full {
  width: 100%;
  height: 100%;
}

.grow {
  flex-grow: 1;
  flex-shrink: 0;
}

.center {
  place-items: center;
}

.primary {
  color: #38509c;
}

.bg-primary {
  background-color: #38509c;
}

.border-primary {
  border-color: #38509c;
}

.success {
  color: #40e0d0;
}

.bg-success {
  background-color: #40e0d0;
}

.border-success {
  border-color: #40e0d0;
}

.warn {
  color: #ffca00;
}

.bg-warn {
  background-color: #ffca00;
}

.border-warn {
  border-color: #ffca00;
}

.error {
  color: #ff1b69;
}

.bg-error {
  background-color: #ff1b69;
}

.border-error {
  border-color: #ff1b69;
}

.black {
  color: #2f2f2f;
}

.bg-black {
  background-color: #2f2f2f;
}

.border-black {
  border-color: #2f2f2f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  place-items: center;
  font-size: 11px;
  color: #3a3a3a;
  min-height: -webkit-fill-available;
  position: relative;
  letter-spacing: 0.1rem;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
}

img {
  object-fit: cover;
}

a,
button,
img,
.material-icons {
  user-select: none;
}

button,
a {
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
}

input,
input:focus {
  outline: none;
  padding: 0.5rem;
  border: none;
  line-height: 1.2rem;
}

p {
  line-height: 2em;
  text-align: justify;
}

small {
  font-size: 0.8rem;
  font-weight: lighter;
  color: #efeef8;
}

header {
  width: 100%;
  padding: 2rem 0;
  height: 10rem;
  z-index: 10;
}
header img {
  width: 16rem;
}
header a {
  text-transform: capitalize;
  text-decoration: none;
  color: #ffffff;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  letter-spacing: 2px;
  border-radius: 0.5rem;
  background-color: transparent;
  letter-spacing: 0.2rem;
  font-weight: bold;
}
header nav {
  flex-grow: 1;
  margin-left: 5rem;
}
header nav a:hover {
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}
header #signin {
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  text-transform: uppercase;
  font-weight: bold;
}
header #signin:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

footer {
  min-height: 10rem;
  background-color: #1a1a1a;
  color: #ffffff;
  font-size: 1rem;
  justify-content: center;
}
footer .wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
footer a {
  color: #ffffff;
}
footer a .material-icons {
  margin-right: 1rem;
  font-size: 1.5em;
}
footer a img {
  height: 2.5em;
  margin-left: 1rem;
  margin-top: -0.8em;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes floating {
  0% {
    transform: translateY(-8%);
  }
  100% {
    transform: translateY(8%);
  }
}
@keyframes growing {
  from {
    opacity: 0.1;
    transform: scale(0.8);
  }
  to {
    opacity: 0.3;
    transform: scale(1);
  }
}
section {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
}
section:last-of-type {
  height: 100vh;
}
section:not(:first-of-type) {
  margin-top: calc(15rem * -2);
}
section:nth-of-type(2) {
  margin-top: calc(15rem * -1);
}
section:nth-of-type(even) .triangle {
  border-left-color: #f3f3f3;
}
section:nth-of-type(even) .title {
  color: #38509c;
}
section:nth-of-type(even) .bg {
  background-color: #f3f3f3;
}
section:nth-of-type(odd) .triangle {
  border-left-color: #38509c;
}
section:nth-of-type(odd) .title {
  color: #ffffff;
}
section:nth-of-type(odd) .bg {
  background-color: #38509c;
}
section:nth-of-type(2) {
  z-index: 2;
}
section:nth-of-type(3) {
  z-index: 3;
}
section:nth-of-type(4) {
  z-index: 4;
}

section + section {
  height: calc(100vh + 2 * 15rem);
}

.scroll-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}
.scroll-container section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.scroll-container footer {
  scroll-snap-align: end;
}

.bg {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  position: relative;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  background-color: transparent;
  padding: 0 2rem;
  height: 100%;
  max-height: calc(100vh - 15rem);
}

.triangle {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15rem 0 0 100vw;
  border-color: transparent transparent transparent transparent;
}

.title {
  background-color: transparent;
  height: 15rem;
  max-width: 1440px;
  margin: auto;
  padding: 5rem 2rem 2rem 2rem;
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
}
.title h1 {
  margin: 0;
  font-size: 3rem;
  text-transform: uppercase;
}

.channels {
  margin: 2rem 0;
}
.channels a img {
  width: 3rem;
}
.channels a + a {
  margin-left: 1rem;
}

#landing {
  background-image: url("/assets/img/header.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh + 15rem);
  z-index: 1;
  align-items: center;
}
#landing .wrapper {
  max-height: 100%;
}
#landing .content {
  width: 100%;
  align-items: center;
  height: calc(100vh - 10rem - 4rem);
}
#landing .content .lead {
  width: 45%;
  height: 100%;
  color: #ffffff;
  justify-content: center;
  padding-right: 2rem;
  margin-top: -10rem;
}
#landing .content .lead h2 {
  text-transform: capitalize;
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  line-height: 1.5em;
}
#landing .content .lead p {
  font-size: 1.5rem;
  font-weight: lighter;
  text-align: justify;
  margin: 2em 0;
}
#landing .content .lead .subscribe {
  background-color: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3rem;
  padding: 1rem 0.6rem;
  width: 80%;
  height: 5rem;
}
#landing .content .lead .subscribe input {
  background-color: transparent;
  flex-grow: 1;
  margin: 0 1rem;
  font-size: 1.3rem;
  color: #38509c;
  letter-spacing: 1px;
  border-radius: 3rem;
}
#landing .content .lead .subscribe input::placeholder {
  color: #808080;
}
#landing .content .lead .subscribe a {
  width: 4rem;
  height: 4rem;
  background-color: #38509c;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  color: #ffffff;
}
#landing .content .hero {
  width: 55%;
  height: 100%;
  justify-content: center;
  position: relative;
  margin-top: 8rem;
}
#landing .content .hero #msg {
  position: absolute;
  width: 30%;
  z-index: 1;
  top: 22%;
  left: 18%;
  animation: floating 6s ease-in-out infinite alternate;
  will-change: transform;
}
#landing .content .hero #base {
  z-index: 0;
  width: 100%;
}

.coming {
  opacity: 0.5;
}

.more {
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  height: 4rem;
}
.more .material-icons {
  margin-top: -1.5rem;
  opacity: 0;
}
.more .material-icons:nth-of-type(1) {
  animation: appear 1.5s linear 0.2s infinite;
}
.more .material-icons:nth-of-type(2) {
  animation: appear 1.5s linear 0.4s infinite;
}

.dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: relative;
  margin: 2rem;
  display: inline-block;
  flex-shrink: 0;
}
.dot::after {
  position: absolute;
  top: -1rem;
  left: -1rem;
  border-radius: 100%;
  content: "";
  width: 3rem;
  height: 3rem;
  opacity: 0.5;
  background-color: inherit;
  animation: growing 2s ease-in-out infinite;
}

#how .illustration {
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2rem;
}
#how .illustration img {
  height: 75vh;
}
#how .steps {
  padding: 0;
}
#how .steps h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1em 0;
  text-transform: capitalize;
  line-height: 1.5em;
}
#how .steps h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1.5em 1rem 1em 1rem;
  text-transform: capitalize;
}
#how .steps p {
  font-size: 1.1rem;
  font-weight: lighter;
  margin: 0 1rem;
}
#how .steps a {
  text-transform: uppercase;
  margin: 0.5rem 1rem 1.5rem 1rem;
  font-weight: lighter;
  font-size: 0.9rem;
  text-decoration: underline;
  color: #8b98c1;
}
#how #design {
  margin-top: 5vh;
  transform: translateX(-50vw);
}
#how #workflow {
  margin-top: 16vh;
  transform: translateX(-50vw);
}
#how #identify {
  margin-top: 16vh;
  transform: translateX(50vw);
}
#how #improve {
  margin-top: 8vh;
  transform: translateX(50vw);
}

#features .ui {
  width: 50%;
  height: 100%;
  position: absolute;
  left: -2rem;
  top: -5rem;
  justify-content: center;
  transform: translateX(-50vw);
  z-index: 2;
}
#features .ui img {
  border-radius: 8px;
  width: 100%;
  filter: drop-shadow(8px 8px 18px #1f2e5a);
}
#features .wrapper {
  z-index: 1;
}
#features .info {
  width: 50%;
  height: 100%;
  justify-content: center;
  margin-top: -8rem;
  padding: 0 5rem;
}
#features .feature {
  color: #ffffff;
  margin-bottom: 2rem;
  position: relative;
  transform: translateX(50vw);
}
#features .feature h3 {
  font-size: 2.5rem;
  margin: 1em 0;
  text-transform: capitalize;
}
#features .feature p {
  font-size: 1.2rem;
  font-weight: lighter;
  max-width: 85%;
}
#features .feature .channels {
  margin-bottom: 0;
}
#features .arrow {
  position: absolute;
  bottom: 8rem;
  right: 6rem;
  opacity: 0;
}
#features .arrow img {
  height: 30vh;
  opacity: 0.8;
}